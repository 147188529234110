var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-custom-show-result-mail-cdg"},[_c('div',{staticClass:"modal-custom-show-result-mail-cdg-content"},[_c('b',[_vm._v("Resultat de l'envoi mail :")]),_c('button',{staticClass:"swal2-close",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('closeResultSwal')}}},[_vm._v(" × ")]),_c('div',{staticClass:"mt-3"},[_vm._l((_vm.dataToShow.error),function(error,i){return _c('div',{key:'error-list' + i,staticClass:"mt-3"},[_c('Card',{staticStyle:{"color":"black"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('b',{staticClass:"title-sectfion"},[_vm._v(" Liste des erreurs Pour le client "+_vm._s(error.client_name)+" :")]),(error.message)?_c('div',{staticClass:"item-list"},[_vm._v(" "+_vm._s(error.message)+" ")]):_vm._e(),(
                error.unvalid_extra_mails && error.unvalid_extra_mails.length
              )?_c('div',[_c('div',{staticClass:"title-list"},[_vm._v("Liste de extra mail invalide")]),_vm._l((error.unvalid_extra_mails),function(ExMaIn,iE){return _c('div',{key:'invalid-extra-mail' + iE,staticClass:"item-list"},[_vm._v(" "+_vm._s(ExMaIn)+" ")])})],2):_vm._e()]},proxy:true}],null,true)})],1)}),_vm._l((_vm.dataToShow.success),function(succes,iS){return _c('div',{key:'succes-list' + iS,staticClass:"mt-3"},[_c('Card',{staticStyle:{"color":"black"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('b',{staticClass:"title-section"},[_vm._v(" les email envoi avec succes pour le client "+_vm._s(succes.client_name)+": ")]),(succes.message)?_c('div',{staticClass:"item-list"},[_vm._v(" "+_vm._s(succes.message)+" ")]):_vm._e(),(
                succes.unvalid_extra_mails &&
                  succes.unvalid_extra_mails.length
              )?_c('div',[_c('div',{staticClass:"title-list"},[_vm._v("Liste de extra mail invalide :")]),_vm._l((succes.unvalid_extra_mails),function(ExMaInS,iEs){return _c('div',{key:'invalid-extra-mail-succes' + iEs,staticClass:"item-list"},[_vm._v(" "+_vm._s(ExMaInS)+" ")])})],2):_vm._e(),(
                succes.valid_extra_mails && succes.valid_extra_mails.length
              )?_c('div',[_c('div',{staticClass:"title-list"},[_vm._v("Liste de extra mail valide :")]),_vm._l((succes.valid_extra_mails),function(ExMaVaS,iVs){return _c('div',{key:'valid-extra-mail-succes' + iVs,staticClass:"item-list"},[_vm._v(" "+_vm._s(ExMaVaS)+" ")])})],2):_vm._e()]},proxy:true}],null,true)})],1)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }